import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class ProManagePage extends Component {
  render() {
    if (!this.props.match.params.customerId) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <h1>Managing account {this.props.match.params.customerId}</h1>
      </div>
    );
  }
}

export default withStyles(styles)(ProManagePage);