import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { logoutUser } from "../../actions";
import { myAnalytics } from "../../firebase/firebase";
import { withStyles } from "@material-ui/core/styles";
import { Button, Container, TextField, Typography } from "@material-ui/core";

const styles = (theme) => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class AdminPage extends Component {
  state = { problemTitle: "", } 
 
  componentDidMount() {
    myAnalytics.logEvent("page_view", { page_title: "screen_home" });
  }
  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };
  handleEmailApi = (path) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/${path}`)
      .then(() => {
        alert("Operation complete");
      })
      .catch((error) => {
        alert(error);
      });
  };
  handleProblemTitleChange = ({ target }) => {
    this.setState({ problemTitle: target.value });
  };
  handleAddProblem = () => {
    const data = {
      title: this.state.problemTitle
    };
    axios.post(`${process.env.REACT_APP_API_URL}/problemSave`, data)
      .then(() => {
        alert("Problem saved");
      })
      .catch(error => {
        alert(error);
      })
  };
  render() {
    const { isLoggingOut, logoutError } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">
          Admin
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={() => this.handleEmailApi("emailBuild")}
        >
          Build emails
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => this.handleEmailApi("emailBlast")}
        >
          Send emails
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={() => this.handleEmailApi("emailDispose")}
        >
          Dispose emails
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={this.handleLogout}
        >
          Logout
        </Button>
        {isLoggingOut && <p>Logging Out....</p>}
        {logoutError && <p>Error logging out</p>}
        <TextField
          variant="outlined"
          fullWidth
          label="Title"
          onChange={this.handleProblemTitleChange}
          style={{marginTop: 40}}
        />
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={() => this.handleAddProblem()}
        >
          Add problem
        </Button>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(AdminPage));
