import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from 'axios';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const styles = (theme) => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class ProBuyPage extends Component {
  state = {
    sessionId: null,
    customerId: null,
    loadingError: null,
    upgradeError: null,
  };

  componentDidMount() {
    this.loadData();
  }
  // Loading
  loadData = () => {
    const data = {
      userUuid: this.props.match.params.uuid,
      priceId: 'price_1HbTchD85ViH30SkcRQitIO9',
      successUrl: `${window.location.origin}/#/upgraded/${this.props.match.params.uuid}`,
      cancelUrl: window.location.href,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/proCheckoutSessionCreate`, data)
      .then(res => {
        this.setState({ 
          sessionId: res.data.sessionId,
          customerId: res.data.customerId,
        });
      })
      .catch(error => {
        if (error.response) {
          this.setState({ loadingError: error.response.data.error });
        } else {
          this.setState({ loadingError: error.message });
        }
      })
  }
  // Handlers
  handleUpgrade = async () => {
    console.log("Invoking Stripe Checkout");
    this.setState({ upgradeError: null });
    const stripe = await stripePromise;
    stripe
      .redirectToCheckout({sessionId: this.state.sessionId})
      .catch((error) => {
        console.log(error);
        this.setState({ upgradeError: error.message });
      });
  };
  render() {
    const { classes } = this.props;
    if (!this.props.match.params.uuid || this.state.loadingError) {
      return <Redirect to="/" />;
    } else if (this.state.customerId && !this.state.sessionId) {
      // User has active subscription
      return <Redirect to={`/manage/${this.state.customerId}`} />;
    }
    return (
      <Container component="main" maxWidth="xs">
        <Paper className={classes.containerForm}>
          <Typography component="h1" variant="h5">
            Upgrading {this.props.match.params.uuid}
          </Typography>
          {this.state.upgradeError && (
            <Typography>{this.state.upgradeError}</Typography>
          )}
          {!this.state.sessionId && <CircularProgress />}
          {this.state.sessionId && (
            <Button
              fullWidth
              variant="contained"
              color="default"
              onClick={this.handleUpgrade}
            >
              Upgrade
            </Button>
          )}
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(ProBuyPage);
