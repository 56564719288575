import { myFirebaseApp } from "../firebase/firebase";
import ELUser from '../data/model/eluser'; 
import ELDataStore from '../data/datastores/eldatastore'

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_CLEAR = "LOGIN_CLEAR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const USER_UPDATE = "USER_UPDATE";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const loginError = error => {
  return {
    type: LOGIN_FAILURE,
    message: error.message
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutError = error => {
  return {
    type: LOGOUT_FAILURE,
    message: error.message
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const requestLoginClear = () => {
  return {
    type: LOGIN_CLEAR
  };
};

export const receiveUser = user => {
  return {
    type: USER_UPDATE,
    user
  };
};

export const loginUser = (email, password) => dispatch => {
  console.log("Logging in user");
  dispatch(requestLogin());
  myFirebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(_ => {
      finishLocalLogin(dispatch);
    })
    .catch(error => {
      console.log(error);
      dispatch(loginError(error));
    });
};

export const logoutUser = () => dispatch => {
  console.log("Logging out user");
  dispatch(requestLogout());
  myFirebaseApp
    .auth()
    .signOut()
    .then(() => {
      // Make sure to clear the datastores when the user logs out
      ELDataStore.destroy();
      dispatch(receiveLogout());
    })
    .catch(error => {
      console.log(error);
      dispatch(logoutError(error));
    });
};

export const verifyAuth = () => dispatch => {
  console.log("Verifying auth state");
  dispatch(verifyRequest());
  var listener = myFirebaseApp.auth().onAuthStateChanged(user => {
    console.log("Firebase auth state changed");
    if (user) {
      finishLocalLogin(dispatch);
    }
    dispatch(verifySuccess());
    // Unregister this listener as it's only used the first time to check valid registration
    listener();
  });
};

export function saveUser(dispatch, user) {
  console.log("Saving user profile");
  dispatch(receiveUser(user));
  ELDataStore.userStore.create(user, {merge: true});
}

function finishLocalLogin(dispatch) {
  var localUser = new ELUser(myFirebaseApp.auth().currentUser);
  console.log("Refreshing user profile: id=" + localUser.documentId());
  // Dispatch once to load page immediately
  dispatch(receiveLogin(localUser));
  // Refresh profile from Firestore
  var onStoreItemListener = () => {
    var onComplete = () => {
      console.log("Finishing local login: user=" + JSON.stringify(localUser));
      dispatch(receiveLogin(localUser));
    }
    onStoreItemListener.onItemLoaded = onComplete;
    onStoreItemListener.onItemLoadingError = onComplete;
  };
  ELDataStore.userStore.getItem(localUser.documentId(), onStoreItemListener)
}
