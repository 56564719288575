import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
} from "@material-ui/core";

const styles = theme => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class ProBuySuccessPage extends Component {
  render() {
    const { classes } = this.props;
    if (!this.props.match.params.uuid) {
      return <Redirect to="/" />;
    }
    return (
      <Container component="main" maxWidth="xs">
          <Paper className={classes.containerForm}>
            <Typography component="h1" variant="h5">
              Success! Please use the email we have sent you to manage your subscription
            </Typography>
          </Paper>
        </Container>
    );
  }
}

export default withStyles(styles)(ProBuySuccessPage);