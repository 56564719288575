import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class SubscribeSuccessPage extends Component {
  render() {
    if (!this.props.match.params.email) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <h1>Thanks for subscribing!</h1>
      </div>
    );
  }
}

export default withStyles(styles)(SubscribeSuccessPage);