import { Subject } from 'rxjs';
import * as utils from "../../../utils/utils"
var Mutex = require('async-mutex').Mutex;

export default class ELCollectionStore {
  mItems = [];
  // Callbacks
  mItemsLoadedObservable = new Subject();
  mOneTimeListeners = [];
  // Firebase
  mRegistration = null;
  // Snapshots
  mSnapshotAdded = false;
  mCacheLoadedOnce = false;
  mItemsLoadedOnce = false;
  mParseMutex = new Mutex();
  mParseMutexRelease = null;

  getTargetObject() { return null; }
  getCollectionQuery() { return null; }
  getTag() { return null; }
  getItemsLoadedSubscription() {
    return this.mItemsLoadedObservable;
  }
  destroy() {
    this._removeSnapshotListener();
    this.mSnapshotAdded = false;
    this.mCacheLoadedOnce = false;
    this.mItemsLoadedOnce = false;
    this.mItems.length = 0;
    this.mOneTimeListeners.length = 0;
    this._releaseMutex();
  }
  async getItems(oneTimeListener) {
    try {
      // Acquire lock
      console.log(`[${this.getTag()}] Acquiring lock`);
      this.mParseMutexRelease = await this.mParseMutex.acquire();
      console.log(`[${this.getTag()}] Acquired lock`);

      if (utils.isValid(oneTimeListener)) {
        this._safelyAdd(this.mOneTimeListeners, oneTimeListener);
      }
      if (this.mSnapshotAdded) {
        // Notify listeners immediately
        if (utils.isValid(oneTimeListener)) {
          console.log(`[${this.getTag()}] Items loaded once. Replying to on-time listeners: items=${this.mItems.length}`);
          oneTimeListener();
          oneTimeListener.onItemsLoaded(this.mItems, false)
          this.mOneTimeListeners = this.mOneTimeListeners.filter((el) => el !== oneTimeListener);
        } else {
          console.log(`[${this.getTag()}] Items loaded once. Broadcasting to subscribers: items=${this.mItems.length}`);
          // sendItemsLoadedEvent(false);
          this.mItemsLoadedObservable.next(this.mItems);
        }
        this._releaseMutex();
      } else {
        // Register snapshot
        this._addSnapshotListener();
      }
    } catch (error) {
      this._releaseMutex();
      this._notifyError(false, error);
    }
  }

  // Firebase

  _removeSnapshotListener() {
    if (utils.isValid(this.mRegistration)) {
      this.mRegistration();
    }
    this.registration = null;
  }
  _addSnapshotListener() {
    console.log(`[${this.getTag()}] Adding snapshot listener`);
    this._removeSnapshotListener();
    var collectionQuery = this.getCollectionQuery();
    if (collectionQuery) {
      // Wait until cache is parsed first
      var cacheFinishedListener = () => {
        // Continue with snapshot
        this.registration = collectionQuery.onSnapshot({ includeMetadataChanges: true }, (snapshot, e) => {
          if (!this.mSnapshotAdded) {
            this.mItems.length = 0;
          }
          this.mSnapshotAdded = true;
          this._releaseMutex();
          if (e) {
            this._notifyError(utils.isValid(snapshot) && snapshot.metadata.fromCache, e);
          } else if (snapshot) {
            console.log(`[${this.getTag()}] Received items from snapshot`);
            this._enqueueSnapshot(snapshot, snapshot.metadata.fromCache, null)
          }
        });
      };
      // Try resolving the required data from cache first
      collectionQuery.get({ source: 'cache' })
        .then(result => {
          console.log(`[${this.getTag()}] Loaded items from cache: items=${result.docChanges().length}`);
          if (!this.mCacheLoadedOnce) {
            this.mItems.length = 0;
          }
          this.mCacheLoadedOnce = true;
          // Parse cache if we have something
          this._enqueueSnapshot(result, true, cacheFinishedListener);
        })
        .catch(error => {
          console.log(`[${this.getTag()}] Failed to load items from cache: error=${error}`);
          cacheFinishedListener();
        });
    }
  }

  // Threading

  _releaseMutex() {
    if (utils.isValid(this.mParseMutexRelease)) {
      console.log(`[${this.getTag()}] Releasing lock`);
      this.mParseMutexRelease();
      this.mParseMutexRelease = null;
    }
  }

  // Parsing and decorating

  _enqueueSnapshot(snapshot, fromCache, cacheListener) {
    this._doParseDocument(snapshot, 0, fromCache, cacheListener)
  }
  _doParseDocument(snapshot, index, fromCache, cacheListener) {
    if (index < snapshot.docChanges().length) {
      var documentChange = snapshot.docChanges()[index];
      var document = documentChange.doc;
      if (document.exists) {
        try {
          // Parse item
          var parsed = Object.assign(this.getTargetObject(), document.data());
          var newIndex = documentChange.newIndex;
          var oldIndex = documentChange.oldIndex;
          //var hasPendingWrites = document.metadata.hasPendingWrites;
          // Decorate
          var onDecorationComplete = () => {
            var onComplete = (decoratedItem) => {
              // Make sure decorated item is valid
              if (utils.isValid(decoratedItem)) {
                switch (documentChange.type) {
                  case "added": {
                    this.mItems[newIndex] = decoratedItem;
                    if (this.mItemsLoadedOnce) {
                    //   notifyItemAdded(newIndex, decoratedItem, hasPendingWrites, fromCache);
                    }
                    break;
                  }
                  case "modified": {
                    this.mItems.splice(oldIndex, 1);
                    this.mItems[newIndex] = decoratedItem;
                    if (this.mItemsLoadedOnce) {
                    //   notifyItemModified(oldIndex, newIndex, decoratedItem, hasPendingWrites, fromCache);
                    }
                    break;
                  }
                  case "removed": {
                    this.mItems.splice(oldIndex);
                    if (this.mItemsLoadedOnce) {
                    //   notifyItemRemoved(oldIndex, decoratedItem, hasPendingWrites, fromCache);
                    }
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
              this._doParseDocument(snapshot, index + 1, fromCache, cacheListener);
            }
            var onError = (error) => {
              console.log(error);
              this._doParseDocument(snapshot, index + 1, fromCache, cacheListener);
            }
            onDecorationComplete.onComplete = onComplete;
            onDecorationComplete.onError = onError;
          };
          this._decorateItem(parsed, onDecorationComplete);
        } catch (error) {
          console.log(error);
          this._doParseDocument(snapshot, index + 1, fromCache, cacheListener);
        }
      } else {
        // Invalid document, proceed to next
        this._doParseDocument(snapshot, index + 1, fromCache, cacheListener);
      }
    } else {
      // We have finished parsing all snapshots, so notify completion
      this.mItemsLoadedOnce = true;
      this._notifySnapshotReady(fromCache);
      if (utils.isValid(cacheListener)) {
        cacheListener();
      }
      if (!fromCache) {
        this._releaseMutex();
      }
    }
  }
  _decorateItem(item, onDecorationComplete) {
    onDecorationComplete();
    onDecorationComplete.onComplete(item);
  }

  // Notifications

  _notifyError(fromCache, error) {
    console.log(error);
    this.mOneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoadingError(error);
    });
    this.mOneTimeListeners.length = 0;
  }
  _notifySnapshotReady(fromCache) {
    console.log(`[${this.getTag()}] Notifying snapshot ready: items=${this.mItems.length} fromCache=${fromCache}`);
    this.mOneTimeListeners.forEach(listener => {
      listener()
      listener.onItemsLoaded(this.mItems, fromCache);
    });
    if (!fromCache) {
      // Allow one-time listeners to get the cached versions too.
      this.mOneTimeListeners.length = 0;
    }
    this.mItemsLoadedObservable.next(this.mItems);
  }

  // Utils

  _safelyAdd(collection, item) {
    if (utils.isValid(item) && !collection.includes(item)) {
      collection.push(item);
    }
  }
}