import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../../actions";
import { requestLoginClear } from "../../actions";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

const styles = theme => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

class LoginPage extends Component {
  state = { email: "", password: "" };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestLoginClear());
  }
  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };
  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };
  handleLogin = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;
    dispatch(loginUser(email, password));
  };
  render() {
    const { classes, loginError, isAuthenticated } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/admin" />;
    } else {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.containerForm}>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              onChange={this.handleEmailChange}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Password"
              type="password"
              onChange={this.handlePasswordChange}
            />
            {loginError && (
              <Typography>
                {loginError}
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              color="default"
              onClick={this.handleLogin}
              className={classes.btnLogin}
            >
              Sign In
            </Button>
          </Paper>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default withStyles(styles)(connect(mapStateToProps)(LoginPage));
