import ELCollectionStore from "./base/elcollectionstore";
import ELUser from "../model/eluser";
import FirestorePathManager from '../../managers/firebase/firestorepathmanager'

export default class ELUsersStore extends ELCollectionStore {
    getTargetObject() {
        return new ELUser(null);
    }
    getCollectionQuery() {
      return FirestorePathManager.getUsersCollection();
    }
    getTag() {
        return "ELUsersStore"
    }
}