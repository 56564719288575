import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { myAnalytics } from "../../firebase/firebase";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  containerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ohnohoney: {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    height: 0,
    width: 0
  },
});

class LandingPage extends Component {
  state = { 
    email: null, 
    nickname: null, 
    isSubscribed: false, 
    subscribeError: null 
  };

  componentDidMount() {
    myAnalytics.logEvent("page_view", { page_title: "screen_landing" });
  }
  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };
  handleNicknameChange = ({ target }) => {
    this.setState({ nickname: target.value });
  };
  handleSubscribe = () => {
    if (this.state.nickname) {
      console.log("Ignoring bot");
      return
    }
    this.setState({ subscribeError: "" });
    const data = {
      email: this.state.email
    };
    axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, data)
      .then(res => {
        this.setState({ isSubscribed: true });
      })
      .catch(error => {
        if (error.response) {
          this.setState({ subscribeError: error.response.data.error });
        } else {
          this.setState({ subscribeError: error.message });
        }
      })
  };
  render() {
    const { classes } = this.props;
    if (this.state.isSubscribed) {
      return <Redirect to={`/subscribed/${this.state.email}`} />;
    } else {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.containerForm}>
            <Typography component="h1" variant="h5">
              Subscribe
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              onChange={this.handleEmailChange}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Nickname"
              onChange={this.handleNicknameChange}
              className={classes.ohnohoney}
            />
            {this.state.subscribeError && (
                <Typography>
                  {this.state.subscribeError}
                </Typography>
              )}
            <Button
              fullWidth
              variant="contained"
              color="default"
              onClick={this.handleSubscribe}
            >
              Subscribe
            </Button>
          </Paper>
        </Container>
      );
    }
  }
}

export default withStyles(styles)(LandingPage);
