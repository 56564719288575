import ELDocumentStore from "./base/eldocumentstore";
import ELUser from "../model/eluser";
import FirestorePathManager from '../../managers/firebase/firestorepathmanager'
import { myFirebase } from "../../firebase/firebase";
import { store } from "../../Root"
import { receiveUser } from "../../actions";

export default class ELUserStore extends ELDocumentStore {
    getTargetObject() {
        return new ELUser(null);
    }
    getDocumentReference(itemId) {
        return this.getDocumentCollection()
            .where(myFirebase.firestore.FieldPath.documentId(), "==", itemId);
    }
    getDocumentCollection() {
        return FirestorePathManager.getUsersCollection();
    }
    getTag() {
        return "ELUserStore"
    }
    itemLoaded(item) {
        super.itemLoaded(item);
        if (store.getState().auth.user.uuid === item.documentId()) {
            store.dispatch(receiveUser(item));
        }
    }
}