export default class ELUser {
  static from(json){
    return Object.assign(new ELUser(null), json);
  }
  constructor(firebaseUser) {
    if (firebaseUser) {
      this.uuid = firebaseUser.uid;
      this.displayName = firebaseUser.displayName;
      this.email = firebaseUser.email;
      this.createdDate = new Date(firebaseUser.metadata.creationTime).getTime();
      this.subscription = null
    }
  }
  documentId() {
    return this.uuid;
  }
  asMap() {
    var map = {
      uuid: this.uuid,
      displayName: this.displayName,
      email: this.email,
    };
    if (this.subscription) {
      map.subscription = this.subscription;
    }
    return map;
  }
  firstName() {
    if (this.displayName) {
      return this.displayName.split(" ")[0];
    }
    return null;
  }
  isPro() {
    return this.subscription != null;
  }
  proCustomerId() {
    return this.subscription.customer
  }
}
