import * as utils from "../../../utils/utils"
var Mutex = require('async-mutex').Mutex;

export default class ELDocumentStore {
  // Callbacks
  mOneTimeListeners = [];
  // Firebase
  mRegistration = null;
  // Snapshots
  mParseMutex = new Mutex();
  mParseMutexRelease = null;

  getTargetObject() { return null; }
  getDocumentReference(itemId) { return null; }
  getDocumentCollection() { return null; }
  getTag() { return null; }
  destroy() {
    this.mOneTimeListeners.length = 0;
    this._removeSnapshotListener();
    this._releaseMutex();
  }
  async getItem(itemId, listener) {
    try {
      // Acquire lock
      console.log(`[${this.getTag()}] Acquiring lock`);
      this.mParseMutexRelease = await this.mParseMutex.acquire();
      console.log(`[${this.getTag()}] Acquired lock`);

      this._safelyAdd(this.mOneTimeListeners, listener);
      this._addSnapshotListener(itemId);
    } catch (error) {
      this._releaseMutex();
      console.log(error);
      this._notifyError(false, error);
    }
  }

  // CRUD

  create(item, options) {
    if (options !== undefined) {
      this.getDocumentCollection().doc(item.documentId()).set(item.asMap(), options);
    } else {
      this.getDocumentCollection().doc(item.documentId()).set(item.asMap());
    }
  }
  delete(item) {
    this.getDocumentCollection().doc(item.documentId()).delete();
  }

  // Firebase

  _removeSnapshotListener() {
    if (utils.isValid(this.mRegistration)) {
      this.mRegistration();
    }
    this.mRegistration = null;
  }
  _addSnapshotListener(itemId) {
    console.log(`[${this.getTag()}] Adding snapshot listener`);
    this._removeSnapshotListener();
    var documentReference = this.getDocumentReference(itemId);
    // Wait until cache is parsed first
    var cacheFinishedListener = () => {
      // Continue with snapshot
      this.mRegistration = documentReference.onSnapshot({ includeMetadataChanges: true }, (snapshot, e) => {
        this._releaseMutex();
        if (utils.isValid(e)) {
          this._notifyError(utils.isValid(snapshot) && snapshot.metadata.fromCache, e);
        } else if (utils.isValid(snapshot)) {
          console.log(`[${this.getTag()}] Received item from snapshot`);
          this._enqueueSnapshot(snapshot, snapshot.metadata.fromCache, null)
        }
      });
    };
    // Try resolving the required data from cache first
    documentReference.get({ source: 'cache' })
      .then(result => {
        console.log(`[${this.getTag()}] Loaded item from cache`);
        // Parse cache if we have something
        this._enqueueSnapshot(result, true, cacheFinishedListener);
      })
      .catch(error => {
        console.log(`[${this.getTag()}] Failed to load item from cache: error=${error}`);
        cacheFinishedListener();
      });
  }

  // Threading

  _releaseMutex() {
    if (utils.isValid(this.mParseMutexRelease)) {
      console.log(`[${this.getTag()}] Releasing lock`);
      this.mParseMutexRelease();
      this.mParseMutexRelease = null;
    }
  }

  // Parsing and decorating
  
  _enqueueSnapshot(snapshot, fromCache, cacheListener) {
    this._doParseDocument(snapshot, false, fromCache, cacheListener)
  }
  _doParseDocument(snapshot, somethingPending, fromCache, cacheListener) {
    try {
      // We are expecting a single result here, so only fetch the first in the list
      var parsed = snapshot.docs.length > 0 ? Object.assign(this.getTargetObject(), snapshot.docs[0].data()) : undefined;
      if (!utils.isValid(parsed) || Object.keys(parsed).length <= 0) {
        if (!fromCache) {
          // Only notify of errors if load is not from cache
          this._notifyError(fromCache, new Error("Item is null"));
        }
        if (utils.isValid(cacheListener)) {
          cacheListener();
        }
      } else {
        // Decorate
        var decorationListener = () => {
          var onComplete = (decoratedItem) => {
            // Make sure decorated item is valid
            if (utils.isValid(decoratedItem)) {
              this.itemLoaded(decoratedItem);
              this._notifyItemLoaded(decoratedItem, somethingPending || snapshot.metadata.hasPendingWrites, fromCache);
              if (utils.isValid(cacheListener)) {
                cacheListener();
              }
              if (!fromCache) {
                this._releaseMutex();
              }
            }
          }
          var onError = (error) => {
            this._onParseError(error, fromCache, cacheListener);
          }
          decorationListener.onComplete = onComplete;
          decorationListener.onError = onError;
        };
        this._decorateItem(parsed, decorationListener);
      }
    } catch (error) {
      this._onParseError(error, fromCache, cacheListener);
    }
  }
  _onParseError(e, fromCache, cacheListener) {
    console.log(e);
    this._notifyError(fromCache, e);
    if (utils.isValid(cacheListener)) {
      cacheListener();
    }
    if (!fromCache) {
      this._releaseMutex();
    }
  }
  _decorateItem(item, listener) {
    listener();
    listener.onComplete(item);
  }
  itemLoaded(item) {
    // No-op
  }

  // Notifications

  _notifyError(fromCache, error) {
    console.log(error);
    this.mOneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoadingError(error);
    });
    this.mOneTimeListeners.length = 0;
  }
  _notifyItemLoaded(item, hasPendingWrites, fromCache) {
    console.log(`[${this.getTag()}] Notifying snapshot ready: fromCache=${fromCache}`);
    this.mOneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoaded(item, fromCache);
    });
    this.mOneTimeListeners.length = 0;
  }

  // Utils

  _safelyAdd(collection, item) {
    if (utils.isValid(item) && !collection.includes(item)) {
      collection.push(item);
    }
  }
}