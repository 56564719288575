import { myFirestore } from "../../firebase/firebase";

var FirestorePathManager = {
    getUsersCollection() {
        return this._getCollectionReference("users");
    },

    // Utils

    _getCollectionReference(path) {
        return myFirestore.collection(path);
    },
    _buildUserSpecificPath(user, path) {
        if (path) {
            return `users/${user.documentId()}/${path}`;
        }
        return `users/${user.documentId()}`;
    }
}
export default FirestorePathManager;