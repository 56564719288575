import ELUserStore from "./eluserstore"
import ELUsersStore from "./elusersstore"

var ELDataStore = {
    userStore: new ELUserStore(),
    usersStore: new ELUsersStore(),
    destroy() {
        this.userStore.destroy();
        this.usersStore.destroy();
    }
}
export default ELDataStore;