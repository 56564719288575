import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./home/ProtectedRoute";

import LandingPage from "./landing/LandingPage";
import SubscribeSuccessPage from "./landing/SubscribeSuccessPage";
import ProBuyPage from "./landing/ProBuyPage";
import ProBuySuccessPage from "./landing/ProBuySuccessPage";
import ProManagePage from "./landing/ProManagePage";
import LoginPage from "./signin/LoginPage";
import AdminPage from "./home/AdminPage";

function App(props) {
  const { isAuthenticated, isVerifying } = props;
  return (
    <Switch>
      {/* Public */}
      <Route path="/subscribed/:email" component={SubscribeSuccessPage} />
      <Route path="/upgrade/:uuid" component={ProBuyPage} />
      <Route path="/upgraded/:uuid" component={ProBuySuccessPage} />
      <Route path="/manage/:customerId" component={ProManagePage} />
      {/* Protected */}
      <Route path="/signin" component={LoginPage} />
      <ProtectedRoute
        path="/admin"
        component={AdminPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <Route path="/" component={LandingPage} />
    </Switch>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(App);
